.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.footer{
  background-color: #ccc;
}

.login-page .login-inner {
  max-width: 550px;
  min-height: 14rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 100px;
  text-align: center;
}
.login-page .password-str { margin: 0 auto;}
.login-page .scatola {
  min-height: 17rem;
}
.login-page .logo {
  width: 300px;
  margin: 1em auto 0em;

}
.login-page .titolo {
  color: #2486c9;
}
.login-page .accessi {
  text-align: left;
}
.login-page button.full,
.login-page .MuiFormControl-root,
.login-page a.full {
  width: 20rem!important;
}
.form.login-page .form__custom-button {
  margin-top: 50px;
}


/* BUBBLES */
.area{

  width: 100%;
  height:100vh;
  
 
}
.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.bg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  bottom: -160px;
  -webkit-animation: square 25s infinite;
  animation: square 25s infinite;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}
.bg-bubbles li:nth-child(1) {
  left: 10%;
}
.bg-bubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 17s;
          animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 22s;
          animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(5) {
  left: 70%;
}
.bg-bubbles li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
  background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 11s;
          animation-delay: 11s;
}
:root {
  --animate-start: 0;
  --animate-end: -120vh;
}

@-webkit-keyframes square {
  0% {
    -webkit-transform: translateY( var(--animate-start));
            transform: translateY(var(--animate-start));
  }
  100% {
    -webkit-transform: translateY(var(--animate-end)) rotate(600deg);
            transform: translateY(var(--animate-end)) rotate(600deg);
  }
}
@keyframes square {
  0% {
    -webkit-transform: translateY( var(--animate-start));
            transform: translateY(var(--animate-start));
  }
  100% {
    -webkit-transform: translateY(var(--animate-end)) rotate(600deg);
            transform: translateY(var(--animate-end)) rotate(600deg);
  }
}

@media (max-width: 600px) { 

  .login-page .controlla_versione_aggiornata .MuiGrid-container {    
    align-items: center;
    justify-content: center;
    display: flex;}

  
}
@media (max-width: 600px) { 

  .login-page .login-inner { padding:10px; margin-top: 10px;}
  .login-page button.full,
  .login-page .MuiFormControl-root,
  .login-page a.full {
    width: 100%!important;
  }
  .login-page .logo {
    width: 100%;
    max-width: 300px;
  }
  .login-page .controlla_versione_aggiornata { padding:0; text-align: center; width: 100vw;}
  .login-page .controlla_versione_aggiornata .MuiGrid-container{ padding:5px;}
  
}
